'use strict';

var bonusProductsView = require('../bonus-products-view'),
	dialog = require('../dialog'),
	productTile = require('../product-tile'),
	addToCart = require('./product/addToCart'),
    membersOnlyModalAnother = require('./product/membersOnlyModalAnother'),
	cartStoreInventory = require('../storeinventory/cart'),
	util = require('../util'),
	quickview = require('../quickview'),
	imagesLoaded = require('imagesloaded');

var resources = 'ConfiguratorResources' in window ? window.ConfiguratorResources : {};

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
	$('#cart-table').on('click', '.item-edit-details a:not(.configurable-product .item-edit-details a):not(.сustomizable-product .item-edit-details a)', function (e) {
		e.preventDefault();
		var subOptionSelected = false;

		var params = {
			url: e.target.href,
			source: 'cart'
		};

		if (window.OG && window.OG.getOptins() && $(this).parents('.cart-row').next('.og-cart-container-inner').find('og-offer').is('[subscribed]')) {
			subOptionSelected = $(this).parents('.cart-row').next('.og-cart-container-inner').find('og-offer og-select-frequency')[0].shadowRoot.querySelector('og-select').shadowRoot.querySelector('select').value;
			params.url = util.appendParamsToUrl(params.url, {subOptionSelected: subOptionSelected})
		}

		if (util.isMobile() || SitePreferences.DISABLE_QUICK_VIEW) {
			window.location.href = subOptionSelected ? util.appendParamsToUrl(this.href, {subOptionSelected: subOptionSelected}) : this.href;
			return;
		}

		quickview.show(params);
	});

	$('#cart-table').on('click', '.product-list-item a', function (e) {
		e.preventDefault();
		var subOptionSelected = false;

		var params = {
			url: e.target.href
		};

		if (window.OG && window.OG.getOptins() && $(this).parents('.cart-row').next('.og-cart-container-inner').find('og-offer').is('[subscribed]')) {
			subOptionSelected = $(this).parents('.cart-row').next('.og-cart-container-inner').find('og-offer og-select-frequency')[0].shadowRoot.querySelector('og-select').shadowRoot.querySelector('select').value;
			params.url = util.appendParamsToUrl(params.url, {subOptionSelected: subOptionSelected});
		}

		window.location.href = subOptionSelected ? util.appendParamsToUrl(this.href, {subOptionSelected: subOptionSelected}) : this.href;
		return;
	});

	$('#cart-table').on('click', '.configurable-product .item-edit-details a', function (e) {
		e.preventDefault();
		var params = {};
		var subOptionSelected = false;
		if (window.OG && window.OG.getOptins() && $(this).parents('.cart-row').next('.og-cart-container-inner').find('og-offer').is('[subscribed]')) {
			subOptionSelected = $(this).parents('.cart-row').next('.og-cart-container-inner').find('og-offer og-select-frequency')[0].shadowRoot.querySelector('og-select').shadowRoot.querySelector('select').value;
			params.subOptionSelected = subOptionSelected;
		}
		if (params.subOptionSelected) {
			window.location.href = util.appendParamsToUrl(this.href, params);
		} else {
			window.location.href = this.href;
		}
	});

	$('#cart-table').on('click', '.item-image a', function (e) {
		e.preventDefault();

		if (util.isMobile() || SitePreferences.DISABLE_QUICK_VIEW) {
			window.location.href = this.href;
			return;
		}

		var $a = $(this).closest('tr').find('.item-edit-details a:not(.configurable-product .item-edit-details a):not(.сustomizable-product .item-edit-details a)');

		quickview.show({
			url: $a.attr('href'),
			source: 'cart'
		});
	});

	$('#cart-table').on('click', 'a.myjoys-prod-img, a.myjoys-prod-name', function (e) {
		e.preventDefault();
		var pli = $(this).data('pli');
		var editDetailsBtn = '#editDetails_'.concat(pli);
		$(editDetailsBtn).trigger('click');
	});

	// override enter key for coupon code entry
	$('form input[name$="_couponCode"]').on('keydown', function (e) {
		if (e.which === 13 && $(this).val().length === 0) { return false; }
		if (e.which === 13 && $(this).val().length !== 0) {
			$('form button[name$="_addCoupon"]').click();
			return false;
		}
	});
	$('.bonus-products a').on('click', function (e) {
		e.preventDefault();
		bonusProductsView.show(this.href);
	});
	// added to window for CQuotient, self-executing for fallback
	(window.horizontalCarouselInit = function () {
		var $hCarousel = $('.horizontal-carousel');
		// do not initialize twice
		if ($hCarousel.hasClass('slick-slider')) {
			return;
		}
		$hCarousel.slick({
			slidesToScroll: 1,
			slidesToShow: 3,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 2
					}
				}
			]
		});
	})();
	$('.quantity-dropdown, input[id$="_packaging_option"]').on('change', function () {
		$(this).parents('form#cart-items-form').submit();
	});
	$('#go-checkout').on('click', function (e) {
		e.preventDefault();
		$(this).parents('#primary').find('button.go-checkout').click();
	});
	$('button.go-checkout').on('click', function (e) {
		e.preventDefault();
		if ($(this).data().hasOwnProperty('dialogurl')) {
			dialog.open({
				url: $(this).data('dialogurl'),
				options: {
					title: resources.PREMIUM_MEMBERSHIP_REQUIRED_TITLE,
					open: function () {
						$('.premium-membership-required-removal').on('click', 'button.close-dialog', function (e) {
							e.preventDefault();
							$('.ui-dialog-content').dialog('close');
						});
					}
				}
			});

			return false;
		}

		var button = $(this);
		var $form = $(this).parents('form');
		$('<input/>').attr({
			type: 'hidden',
			name: button.attr('name'),
			value: button.attr('value')
		}).appendTo($form);

		$form.submit();
	});
	productTile.swatchImageCkick();

	// coupon code toggle
	var $couponControls = $('.coupon-header button');
	var $couponExpandable = $('#' + $couponControls.attr('aria-controls'));
	$couponControls.on('click', function (e) {
		e.preventDefault();
		var expanded = $(this).attr('aria-expanded') === 'true';

		$couponControls.attr('aria-expanded', !expanded);
		$couponExpandable.attr('aria-hidden', expanded);
		expanded ? $couponExpandable.slideUp() : $couponExpandable.slideDown();
	});

    $('.action-carousel').on('click', '.moretxt, .closetxt', function (e) {
		e.preventDefault();
		$('.product-swatches').removeClass('expanded');

		var swatchURL = $(this).parents('.swatch-list').find('.swatch');

        if (util.isMobile() || SitePreferences.DISABLE_QUICK_VIEW) {
			window.location.href = swatchURL.attr('href');
			return;
		}

		quickview.show({
			url: swatchURL.attr('href'),
			source: 'quickview'
		});
	});

	$('.action-carousel').on('click', '.swatch, .thumb-link', function (e) {
		e.preventDefault();

        if (util.isMobile() || SitePreferences.DISABLE_QUICK_VIEW) {
			window.location.href = this.href;
			return;
		}

		quickview.show({
			url: $(this).attr('href'),
			source: 'quickview'
		});
	});
	imagesLoaded('.category-rocommendations').on('done', function () {
        if ($('.category-recommendations .listing-item').length > 5) {
            $('.category-recommendations .category-listing').addClass('more-than-5');
        }
        $('.category-recommendations').addClass('images-loaded');
        $('.category-recommendations .categoty-image').syncHeight();
    });

	if ($('.product-availability-list .not-available').length) {
		$('.go-checkout').attr('disabled', true);
		$('.cart-action-paypal').hide();
	}

    $('.coupon-code-input').on('focus', function () {
        $(this).removeClass('input-success input-error')
    })

    $('.item-details').each(function () {
        if ($(this).find('.item-edit-details').length === 0) {
            $(this).find('.item-user-actions').addClass('no-edit-details');
            $(this).parents('.cart-row').find('.item-image').addClass('no-edit-details');
        }
    });
}


function initAvailabilitySectionPosition() {
	var availabilitySection = $('.product-availability-list');
	var additionalMargin = 10;

	if (availabilitySection.length > 0) {
		availabilitySection.each(function (index, item) {
			var margin = 0;
			var ctaSection = $(item).next();

			if ($(window).width() >= 768) {
				margin = $(item).height() + additionalMargin
			}

			ctaSection.css('margin-top', margin);
		})
	}
}

function checkoutStickBlock() {
    if (util.getViewport() <= 1200 && $('.cart-footer-inner .cart-actions').length) {
        if (util.elementInViewport($('.cart-footer-inner .cart-actions').get(0), 0)) {
            $('.cart-action-checkout').removeClass('cart-action-checkout-sticky-visible')
        } else {
            $('.cart-action-checkout').addClass('cart-action-checkout-sticky-visible')
        }
    }
}

function ogBlockPosition() {
    var windowWidth = $(window).width();
    $('.cart-row').each(function() {
        if($(this).find('.promo-adjustment').length > 0) {
            var promoHeight = $(this).find('.promo-adjustment').height();

            if(promoHeight > 60) {
                $(this).next('.og-cart-container-inner').find('.og-cart-container').css({'bottom': '24px', 'margin-bottom': '0'});
                if ($(this).next('.og-cart-container-inner').find('.og-cart-container').find('og-offer').length > 0)
                {
                    $(this).find('.in-wishlist').hide();
                }
            }

            if(promoHeight > 74) {
                $(this).next('.og-cart-container-inner').find('.og-cart-container').css({'bottom': '0', 'margin-bottom': '23px'});
                if ($(this).next('.og-cart-container-inner').find('.og-cart-container').find('og-offer').length > 0)
                {
                    $(this).find('.in-wishlist').hide();
                }
            }

            if(windowWidth < 768) {
                if ($('.price-mobile-container').length > 0) {
                   var priceMobileHeight = $('.price-mobile-container').height();
                    $(this).css('min-height', promoHeight + priceMobileHeight + 45 + 'px');
                } else {
                    $(this).css('min-height', promoHeight + 65 + 'px');
                }
            } else {
                $(this).css('min-height', 'auto');
            }
        }
    })
}

function checkoutOutOfStock() {
    $('.cart-row').each(function() {
        if($(this).find('.item-quantity').find('.not-available').length > 0 || $(this).find('.availability-mobile-td').find('.not-available').length > 0) {
            $(this).find('.low-inventory-msg').hide()
        }
    });
}

function summaryBlockPosition() {
    if ($('.cart-container-global-right').length) {
        var summaryBlock = $('.cart-container-global-right').detach();
        if (util.getViewport() <= 1200) {
            summaryBlock.insertAfter('.global-cart');
        } else {
            summaryBlock.insertAfter('.cart-container-global-left');
        }
    }
 }

$(window).on('resize', function () {
    if ($('.category-recommendations.images-loaded').length) {
        $('.category-recommendations .categoty-image').css('height', 'auto');
        $('.category-recommendations .categoty-image').syncHeight();
	}

	initAvailabilitySectionPosition();
    summaryBlockPosition();
    ogBlockPosition();
});

$(window).on('scroll', function () {
    checkoutStickBlock();
});

$(document).ready(function () {
    checkoutStickBlock();
});


exports.init = function () {
	initializeEvents();
    addToCart();
    membersOnlyModalAnother();
    summaryBlockPosition();
    ogBlockPosition();
    checkoutOutOfStock();
    if (SitePreferences.STORE_PICKUP) {
		cartStoreInventory.init();
	}
	initAvailabilitySectionPosition();
};
